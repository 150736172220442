/// <reference types="zone.js" />

import { Observable } from 'rxjs';

/**
 * Angular's HttpClient triggers change detection once events are
 * fired on the internal XMLHttpRequest. We can't simply use
 * `runOutsideAngular(() => http.get(...))` since the XHR is created
 * eagerly when the observable is subscribed to, not just when it is returned.
 * This is only used to avoid running change detections, for instance, when we're
 * running this in local states.
 */
export function callHttpOutsideAngular<T>(httpFactory: () => Observable<T>) {
  if (typeof Zone === 'undefined') return httpFactory();

  const root = Zone.root;

  return new Observable<T>((subscriber) =>
    root.run(() => httpFactory().subscribe(subscriber))
  );
}
