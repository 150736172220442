import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import {
  inject,
  Injectable,
  makeStateKey,
  NgZone,
  TransferState,
} from '@angular/core';
import type { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Share } from '@ngspot/rxjs/decorators';
import { Observable, of, tap } from 'rxjs';

import { assertDefined, enterNgZone } from '@cosmos/util-common';
import { IGNORE_HYDRATION_CACHE, REQUEST } from '@cosmos/util-express';
import { callHttpOutsideAngular } from '@cosmos/zone-less';

import { Languages } from '../consts';
import { languageScopeToPath } from '../utils';

@Injectable({ providedIn: 'root' })
export class TranslationsHttpLoader implements TranslocoLoader {
  private readonly _ngZone = inject(NgZone);
  private readonly _http = inject(HttpClient);
  private readonly _transferState = inject(TransferState);

  private readonly _request = global_isServer
    ? inject(REQUEST, { optional: true })
    : null;

  @Share()
  getTranslation(lang: Languages): Observable<Translation> {
    const path = languageScopeToPath(lang);
    const stateKey = makeStateKey<Translation>(path);

    const request$ = this._http.get<Translation>(`/assets/i18n/${path}.json`, {
      headers: new HttpHeaders({ skipAuthHeader: 'true' }),
      // Skip Angular's native `transferCacheInterceptorFn`, which would automatically
      // put the response into the transfer state, as we will do it manually.
      // Angular's HTTP transfer state cache is active only during hydration.
      // Once hydration is complete, the cache is no longer active.
      // Consider the situation where `storefront/en-us.json` has been loaded
      // during SSR and placed in the transfer state. When the browser tries to
      // retrieve the `storefront/en-us.json` file after hydration is complete, it
      // will not use the transfer state; instead, it will make a real HTTP request.
      context: new HttpContext().set(IGNORE_HYDRATION_CACHE, true),
    });

    if (global_isServer) {
      ngDevMode &&
        assertDefined(this._request, 'Express request should be defined.');

      // https://expressjs.com/en/api.html#app.locals
      const translations: Map<string, Translation> = (this._request!.app.locals[
        'translations'
      ] ??= new Map());

      // The translations are unlikely to change and can be considered purely
      // static files. The server-side XHR uses the `xhr2` package, which is an API
      // built on top of the `http` package. Every time a request is made, the Node.js
      // application must make an HTTP request for a static file, search through the
      // file system, and prepare it to be sent back.
      // We're using `app.locals` to maintain a map of translations, where the path maps
      // to the corresponding loaded JSON translation object, avoiding the need to load
      // it every time the app is rendered.

      if (translations.has(path)) {
        const translation = translations.get(path)!;
        // If translations are found inside the local Express app cache, we should
        // put them on the transfer state here too.
        this._transferState.set(stateKey, translation);
        return of(translation);
      }

      return request$.pipe(
        tap((translation) => {
          translations.set(path, translation);
          this._transferState.set(stateKey, translation);
        })
      );
    }

    // If we're in the browser, we don't care whether hydration is complete
    // because we will always be searching through the transfer state manually.
    if (global_isBrowser && this._transferState.hasKey(stateKey)) {
      return of(this._transferState.get(stateKey, null)!);
    }

    // This is required to reduce change detection cycles for XHR events.
    // We'd only run change detection once when the translation file is loaded.
    // TODO (ds): We could potentially use `fetch` and wrap it in an `Observable`?
    // Using HttpClient + RxJS (internally) causes the stack trace to be obscured.
    return callHttpOutsideAngular(() => request$).pipe(
      enterNgZone(this._ngZone)
    );
  }
}
