import { APP_ID, inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

const PRIMITIVE_TYPES = ['string', 'number', 'boolean'];

interface StorageEventWithKey extends StorageEvent {
  key: string;
}

export abstract class BaseStorageService {
  protected readonly _appId = inject(APP_ID);
  private readonly _scopePrefix = `${this._appId}-storage:`;

  /**
   * This is a temporary flag to migrate to the prefixed storages without loosing any data.
   * It also helps to indicate logical parts that should be removed once migration is done.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly MAINTAIN_FALLBACK = true;

  /**
   * Event listener to storage changes from another window or tab. Works only with localStorage
   */
  private _windowStorage$ = fromEvent<StorageEvent>(
    inject(WINDOW),
    'storage'
  ).pipe(filter(this._isCurrentStorage));

  /**
   * Triggered anytime there is change to this instance of storage.
   */
  private readonly _stateChanged = new Subject<void>();

  /**
   * Grabs current storage state based on current scope.
   * Triggered anytime there is a change via `_keyChangeState$` and anytime there is a change from another tab or window
   *
   * Note: event notification from other tabs works only for localStorage
   */
  readonly storageState$ = merge(this._stateChanged, this._windowStorage$).pipe(
    startWith(this.readStorage()),
    map(() => this.readStorage()),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(protected readonly _storageInstance: Storage) {}

  /**
   * Set the item value of a certain key in the storage
   * @param key key of item
   * @param value value of item. Can be of any type.
   */
  setItem<T>(key: string, value: T) {
    if (!key) {
      ngDevMode && console.warn('Make sure to pass in key');
      return;
    }

    if (this.MAINTAIN_FALLBACK) {
      this._storageInstance.removeItem(key);
    }

    key = this._getPrefixedKey(key);

    this._storageInstance.setItem(
      key,
      isPrimitive(value) ? value.toString() : JSON.stringify(value)
    );
    this._stateChanged.next();
  }

  /**
   * Get the value of the storage by key.
   * @param key key of item to grab value
   */
  getItem<T>(key: string): T | null {
    if (!key) {
      return null;
    }

    const withoutPrefix = key;

    key = this._getPrefixedKey(key);

    let value = this._storageInstance.getItem(key);
    if (this.MAINTAIN_FALLBACK) {
      if (value === null || value === undefined) {
        // if prefixed value does not exist, try getting the one without prefix
        value = this._storageInstance.getItem(withoutPrefix);
        if (value !== null && value !== undefined) {
          // if value without prefix exists, make it prefixed
          this.setItem(withoutPrefix, value);
        }
      }
      this._storageInstance.removeItem(withoutPrefix);
    }
    if (value === null || value === undefined) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (error: unknown) {
      const failedToParse = error instanceof SyntaxError;

      if (ngDevMode && !failedToParse) {
        console.error(`Failed to parse the storage value "${value}"`);
      }

      return value as T;
    }
  }

  /**
   * Remove the the storage entry by key.
   * @param key key of the storage to remove item
   */
  removeItem(key: string) {
    if (!key) {
      return;
    }

    this._storageInstance.removeItem(this._getPrefixedKey(key));
    if (this.MAINTAIN_FALLBACK) {
      this._storageInstance.removeItem(key);
    }
    this._stateChanged.next();
  }

  /**
   * clears the storage for your app.
   */
  clear() {
    this._storageInstance.clear();
    this._stateChanged.next();
  }

  /**
   * Converts the storage to object with `key`, `value` pairs
   */
  readStorage(): { key: string; value: unknown }[] {
    const keys = Object.keys(this._storageInstance).filter((key) =>
      key.startsWith(this._scopePrefix)
    );
    return keys
      .map((k) => k.slice(this._scopePrefix.length))
      .map((key) => ({
        key,
        value: this.getItem(key),
      }));
  }

  private _getPrefixedKey(key: string) {
    return this._scopePrefix + key;
  }

  /**
   * checks if storageArea is a _storageInstance and if key exists
   */
  private _isCurrentStorage(data: StorageEvent): data is StorageEventWithKey {
    return data.storageArea === this._storageInstance && !!data.key;
  }
}

function isPrimitive(value: unknown): value is string | number | boolean {
  return PRIMITIVE_TYPES.includes(typeof value);
}
