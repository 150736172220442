import { inject, Injectable, InjectionToken } from '@angular/core';
import { NAVIGATOR } from '@ng-web-apis/common';
import { TRANSLOCO_CONFIG } from '@ngneat/transloco';
import {
  concatMap,
  EMPTY,
  expand,
  forkJoin,
  mergeMap,
  Observable,
  of,
  switchMap,
  throwError,
  timer,
} from 'rxjs';

import { IDLE$ } from '@cosmos/tick-scheduler';
import { hasGoodConnection } from '@cosmos/util-connection-state';

import type { Languages } from '../consts';
import type { PreloadLangsParams } from '../interfaces';
import type { CosmosTranslocoConfig } from '../interfaces/config.interface';
import { listAllTranslations, toLangValue } from '../utils';

import { CosmosTranslocoService } from './cosmos-transloco.service';

export const PRELOAD_LANGS_PARAMS = new InjectionToken<PreloadLangsParams>(
  ngDevMode ? 'PreloadLangsParams' : ''
);

@Injectable({ providedIn: 'root' })
export class TranslocoPreloadLangsService {
  private readonly _navigator = inject(NAVIGATOR);
  private readonly _translocoConfig =
    inject<CosmosTranslocoConfig>(TRANSLOCO_CONFIG);
  private readonly _idle$ = inject(IDLE$);
  private readonly _translocoService = inject(CosmosTranslocoService);
  private _params: PreloadLangsParams;
  private readonly _loadedLangs = new Set<Languages>();

  constructor() {
    const providedParams = inject<Partial<PreloadLangsParams>>(
      PRELOAD_LANGS_PARAMS,
      { optional: true }
    );

    this._params = {
      simultaneousRequestsCount: 2,
      initDelay: 10000,
      goodConnectionOnly: true,
      ...(providedParams ?? {}),
    };
    this._init();
  }

  private _init(): void {
    if (
      global_isServer ||
      ngDevMode ||
      (this._params.goodConnectionOnly && !hasGoodConnection(this._navigator))
    ) {
      // disabling preloading for dev mode in order to be able to catch issues
      // with scopes not specified properly
      return;
    }

    // Using queueMicrotask to schedule a timer.
    // This is because Angular isStable property becomes false when a new macrotask (like a timer) is scheduled.
    // queueMicrotask schedules a microtask, which runs after the current script execution, but before any other macrotasks.
    // Therefore, scheduling the timer inside a queueMicrotask won't affect the isStable property, as Angular doesn't wait for microtasks to complete before considering the application stable.
    queueMicrotask(() => {
      timer(this._params.initDelay)
        .pipe(
          switchMap(
            () => this._translocoService.langChanges$ as Observable<Languages>
          ),
          concatMap((lang) => {
            // For each activated language will sequentially load all scopes.
            // Requests are batched according to "simultaneousRequestsCount" value,
            // so that if simultaneousRequestsCount === 2 and there're 20 scopes, it will make 10 iterations before loading everything.

            // If language is changed before all scopes are loaded, it will finish loading for the previous language
            // before proceeding with a next one.
            const queue = this._getQueue(lang);
            return of(null).pipe(
              expand(() => {
                if (queue.length) {
                  return this._preloadPart(queue);
                }
                this._loadedLangs.add(lang);
                return EMPTY;
              })
            );
          })
        )
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        .subscribe();
    });
  }

  private _getQueue(lang: Languages): string[][] {
    if (this._loadedLangs.has(lang)) {
      return [];
    }

    const translations = listAllTranslations(
      this._translocoConfig.availableScopes
    ).filter((t) => t.lang === lang);

    const queue = [];

    const count = this._params.simultaneousRequestsCount;
    for (let index = 0; index < translations.length; index += count) {
      queue.push(
        translations.slice(index, index + count).map((el) => toLangValue(el))
      );
    }
    return queue;
  }

  private _preloadPart(queue: string[][]) {
    if (!queue.length) {
      return throwError(() => new Error('Queue is empty'));
    }
    const requests = queue
      .shift()!
      .map((lang) => this._translocoService.load(lang));
    return this._idle$.pipe(mergeMap(() => forkJoin(requests)));
  }
}
