export class MemoryStorage implements Storage {
  private readonly _data = new Map<string, string>();

  getItem(key: string): string | null {
    const value = this._data.get(key);
    return value !== undefined ? value : null;
  }

  setItem(key: string, value: string): void {
    this._data.set(key, value);
  }

  removeItem(key: string): void {
    this._data.delete(key);
  }

  clear(): void {
    this._data.clear();
  }

  get length(): number {
    return this._data.size;
  }

  key(index: number): string | null {
    const keys = [...this._data.keys()];
    return index >= 0 && index < keys.length ? keys[index] : null;
  }
}
