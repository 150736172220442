/* eslint-disable no-restricted-globals */
/// <reference types="jest" />
/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
import { ɵglobal } from '@angular/core';

/**
 * @description This function returns the zone un-patched API for the specific Browser API.
 * If no target is passed, the window is used instead.
 */
export function getZoneUnPatchedApi<
  N extends keyof (Window & typeof globalThis),
>(name: N): (Window & typeof globalThis)[N];

export function getZoneUnPatchedApi<T extends object, N extends keyof T>(
  target: T,
  name: N
): T[N];

export function getZoneUnPatchedApi<
  T extends object,
  N extends keyof T & string,
>(targetOrName: T | string, name?: N) {
  // If the user has provided the API name as the first argument, for instance:
  // `const addEventListener = getZoneUnPatchedApi('addEventListener');`
  // Then we just swap arguments and make `global` or `window` as the default target.
  if (typeof targetOrName === 'string') {
    name = targetOrName as N;
    // Fallback to `ɵglobal` since `window` is undefined when running in Node.js.
    targetOrName = (typeof window !== 'undefined' ? window : ɵglobal) as T;
  }

  return (
    targetOrName[('__zone_symbol__' + name) as keyof T] || targetOrName[name!]
  );
}

export const unpatchedSetTimeout: typeof window.setTimeout =
  getZoneUnPatchedApi('setTimeout');

export const unpatchedSetInterval: typeof window.setInterval =
  getZoneUnPatchedApi('setInterval');

export const unpatchedClearInterval: typeof window.clearInterval =
  getZoneUnPatchedApi('clearInterval');

export const UnpatchedPromise = (() => {
  // We shouldn't use unpatched API when the promise is resolved on the Node.js side
  // since the task must be scheduled within the Angular zone and stand by Universal.
  if (
    (typeof global_isServer !== 'undefined' && global_isServer) ||
    (ngDevMode && typeof jest !== 'undefined')
  ) {
    return Promise;
  }

  return getZoneUnPatchedApi('Promise');
})();
