// THIS FILE IS BEING UPDATED USING GENERATORS. DO NOT EDIT MANUALLY.
//
// Find out more about the usage of i18n generators here libs/cosmos/util-translations/README.md

/** Languages that are currently supported */
export enum Languages {
  EnUs = 'en-us',
  EnCa = 'en-ca',
  FrCa = 'fr-ca',
  Es = 'es',
}
