import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { LocalStorageService } from '@cosmos/data-access-storage';

import { CosmosTranslocoService } from './cosmos-transloco.service';

const STORAGE_LANGUAGE_KEY = 'i18n_selected_lang';

@Injectable({ providedIn: 'root' })
export class TranslocoPersistLangService {
  private readonly _localStorageService = inject(LocalStorageService);

  private readonly _initializedSubject$ = new BehaviorSubject(false);
  readonly initialized$ = this._initializedSubject$.asObservable();

  constructor(private _service: CosmosTranslocoService) {
    if (global_isBrowser) {
      this._init();
    }
  }

  clear() {
    if (!global_isBrowser) {
      return;
    }
    this._localStorageService.removeItem(STORAGE_LANGUAGE_KEY);
  }

  private _updateStorageOnLangChange(): Subscription {
    return this._service.langChanges$.pipe(skip(1)).subscribe((lang) => {
      this._save(lang);
    });
  }

  private _init() {
    // We need to first set the cached lang and then listen to changes
    this._setActiveLang();
    this._updateStorageOnLangChange();
    this._initializedSubject$.next(true);
  }

  private _setActiveLang() {
    const cachedLang =
      this._localStorageService.getItem<string>(STORAGE_LANGUAGE_KEY);

    if (cachedLang && this._service.isLang(cachedLang)) {
      this._service.setActiveLang(cachedLang);
    }
  }

  private _save(lang: string) {
    this._localStorageService.setItem(STORAGE_LANGUAGE_KEY, lang);
  }
}
