// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore this package is used internally by transloco
import { flatten as flattenObject } from 'flat';

import {
  parse,
  TYPE,
  type MessageFormatElement,
  type PluralElement,
  type SelectElement,
} from '@formatjs/icu-messageformat-parser';

const interpolationMatcherSingle = /{{(.*?)}}/;
const interpolationMatcherGlobal = /{{(.*?)}}/g;

type Translation = Record<string, unknown>;

export interface ParsedTranslationData {
  value: string;
  variables: { raw: string; parsed: string; variable: string }[];
  messageFormatElements: {
    element: SelectElement | PluralElement;
    keys: string[];
  }[];
  translationAST: MessageFormatElement[];
}

export function parseTranslationStrings(
  translation: Translation
): Record<string, ParsedTranslationData> {
  const flattened = flattenObject(translation) as Record<string, string>;
  const entries = Object.entries(flattened).map(([key, value]) => {
    /** Variables with curly braces, e.g. `[ "{{ variableOne }}", "{{variableTwo}}" ]` */
    const rawVariables: string[] =
      value.match(interpolationMatcherGlobal) ?? [];

    const parsedVariables =
      rawVariables
        .map((raw: string) => {
          /** Only actual trimmed value, e.g. `variableOne` */
          const parsed =
            raw.match(interpolationMatcherSingle)?.[1].trim() ?? '';
          return {
            parsed,
            raw,
            variable: `VARIABLE__${parsed.toUpperCase()}__PLACEHOLDER`,
          };
        })
        .sort() ?? [];

    // valueWithoutVariables is needed because message format will not parse transloco's tokens (e.g. {{ token }} )
    let valueWithoutVariables = value;
    parsedVariables.forEach((v) => {
      valueWithoutVariables = valueWithoutVariables.replace(v.raw, v.variable);
    });

    let translationAST: MessageFormatElement[];

    try {
      translationAST = parse(escapeHtmlTags(valueWithoutVariables));
    } catch (error) {
      console.log(
        `MessageFormat failed to parse the following string: "${valueWithoutVariables}". Key is "${key}"`
      );
      throw error;
    }

    const messageFormatElements = translationAST
      .filter(
        (item): item is SelectElement | PluralElement =>
          item.type === TYPE.plural || item.type === TYPE.select
      )
      .map((element) => {
        return {
          element,
          keys: Object.keys(element.options).sort(),
        };
      });

    return [
      key,
      {
        translationAST,
        messageFormatElements,
        value,
        variables: parsedVariables,
      },
    ];
  });
  return Object.fromEntries(entries);
}

const ESCAPE_SYMBOL = '__cos_escape__';
/** Escapes given string from further parsing by translation services. */
export function escapeUnparsedExpression(expression: string) {
  return ESCAPE_SYMBOL + expression + ESCAPE_SYMBOL;
}

/**
 * intl-messageformat treats all tags as variables, thus those should be escaped https://formatjs.io/docs/intl-messageformat/#rich-text-support
 *
 * This function does the following:
 * - __cos_escape__ is replaced with a single quote ( ' ). This is needed in rare cases when we want to provide strings with something already escaped
 * - each single quote ( ' ) are replaced with double single quotes ( '' )
 * - < and > symbols are escaped using single quote
 * - consecutive symbols are escaped together (e.g. <> will be escaped as '<>' instead of '<''>')
 */
export function escapeHtmlTags(input: string) {
  // we want to escape each single quote separately, so doing it first
  input = input
    .replace(/'/g, "''")
    .replace(new RegExp(ESCAPE_SYMBOL, 'g'), "'");
  let result = '';
  let escaping = false;
  for (const symbol of input) {
    if (symbol === '<' || symbol === '>') {
      if (!escaping) {
        // add escape after before the symbol we're escaping
        result += "'";
      }
      escaping = true;
    } else {
      if (escaping) {
        // if next symbol should not be escaped, add ' after the escaped expression
        result += "'";
      }
      escaping = false;
    }
    result += symbol;
  }
  if (escaping) {
    // add trailing escape symbol if needed
    result += "'";
  }
  return result;
}

// sample AST outputs
//
// "{length, plural, =1 {Product Selected} other {Products Selected}}"
// [{
//   "type": 6,
//   "value": "length",
//   "options": {
//     "=1": {
//       "value": [
//         {
//           "type": 0,
//           "value": "Product Selected"
//         }
//       ]
//     },
//     "other": {
//       "value": [
//         {
//           "type": 0,
//           "value": "Products Selected"
//         }
//       ]
//     }
//   },
//   "offset": 0,
//   "pluralType": "cardinal"
// }]
//
// "{orderType, select, order {Search for an Order} quote {Search for a Quote} other {Search for an Order}}"
//
// [
//   {
//     "type": 5,
//     "value": "orderType",
//     "options": {
//       "order": {
//         "value": [
//           {
//             "type": 0,
//             "value": "Search for an Order"
//           }
//         ]
//       },
//       "quote": {
//         "value": [
//           {
//             "type": 0,
//             "value": "Search for a Quote"
//           }
//         ]
//       },
//       "other": {
//         "value": [
//           {
//             "type": 0,
//             "value": "Search for an Order"
//           }
//         ]
//       }
//     }
//   }
// ]
