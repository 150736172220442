import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Share } from '@ngspot/rxjs/decorators';
import { map, Observable, of, tap } from 'rxjs';

import { higherOrderLanguageScopes, Languages } from '../consts';
import { languageScopeToPath } from '../utils';

/**
 * Loads combined by scope translations (e.g. "esp/en-us.json").
 * Those are being used in production builds.
 */
@Injectable({ providedIn: 'root' })
export class CombinedTranslationsHttpLoader implements TranslocoLoader {
  /** The array is sorted in order to get longer matches first (e.g. esp vs espMobile) */
  private readonly _higherOrderLanguageScopesSorted = [
    ...higherOrderLanguageScopes.values(),
  ].sort((a, b) => b.length - a.length);

  private readonly _cache = new Map<string, Translation>();

  constructor(private _http: HttpClient) {}

  getTranslation(lang: Languages): Observable<Translation> {
    const { path, innerScope } = this._getLangPath(lang);
    const cache = this._cache.get(path);
    if (cache) {
      return of(innerScope ? cache[innerScope] : cache);
    }
    return this._sharedGetTranslationRequest(path).pipe(
      tap((v) => this._cache.set(path, v)),
      map((v) => (innerScope ? v[innerScope] : v))
    );
  }

  // share decorator makes sure we don't have simultaneous requests to the same url
  @Share()
  private _sharedGetTranslationRequest(path: string): Observable<Translation> {
    return this._http.get<Translation>(`/assets/i18n/${path}.json`, {
      headers: new HttpHeaders({ skipAuthHeader: 'true' }),
    });
  }

  private _getLangPath(lang: Languages): { path: string; innerScope?: string } {
    const langPath = languageScopeToPath(lang);
    // For scoped languages values here will look like "espCommon/en-us".
    // Since we need only the scope value, using "words" util to return "esp/en-us"
    const scope = this._higherOrderLanguageScopesSorted.find((v) =>
      langPath.startsWith(v)
    );
    const langValue = langPath.split('/').pop();
    return {
      path: [scope, langValue].filter(Boolean).join('/'),
      innerScope: langPath
        // customer-portal-common/en-us => common/en-us
        .replace(new RegExp(`^${scope}-?`), '')
        // common/en-us => common
        .replace(new RegExp(`/${langValue}$`), ''),
    };
  }
}
