import {
  ChangeDetectorRef,
  inject,
  Pipe,
  type OnDestroy,
  type PipeTransform,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { HashMap } from '@ngneat/transloco';
import { isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  scan,
  switchMap,
  tap,
} from 'rxjs';

import type { LanguageScope } from '../consts';
import { CosmosTranslocoService } from '../services';

type TranslateFn = (key: string, params?: HashMap) => string;

@Pipe({
  name: 'cosTranslationCtx',
})
export class GetTranslationContextPipe implements PipeTransform, OnDestroy {
  private readonly _cosTranslocoService = inject(CosmosTranslocoService);

  private readonly _keySubject$ = new BehaviorSubject<string | undefined>(
    undefined
  );

  private _cdr = inject(ChangeDetectorRef);

  private _memo = new Map<string, any>();

  private readonly _inlineScopeChanges$: Observable<Set<LanguageScope>> =
    this._keySubject$.pipe(
      filter((v): v is string => !!v),
      distinctUntilChanged(),
      map((key) => this._cosTranslocoService._deduceScopeFromKey(key)),
      scan((acc, c) => (c ? acc.add(c) : acc), new Set<LanguageScope>())
    );

  private _loaded = false;

  constructor() {
    this._inlineScopeChanges$
      .pipe(
        map((allScopes) => [...allScopes.values()]),
        distinctUntilChanged(isEqual),
        tap(() => (this._loaded = false)),
        switchMap((scopes) =>
          this._cosTranslocoService.getLangChanges$(scopes)
        ),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        this._loaded = true;
        this._memo.clear();
        this._cdr.markForCheck();
      });
  }

  transform(prefix: string) {
    this._keySubject$.next(prefix);
    return this.getTranslateFn(prefix);
  }

  ngOnDestroy(): void {
    this._memo.clear();
  }

  protected getTranslateFn(prefix: string | undefined): TranslateFn {
    return (key: string, params?: HashMap) => {
      const withPrefix = prefix ? `${prefix}.${key}` : key;
      const memoKey = params
        ? `${withPrefix}${JSON.stringify(params)}`
        : withPrefix;

      const cacheExists = this._memo.has(memoKey);
      if (!this._loaded && !cacheExists) {
        // if there's no cached value and translations were not loaded yet, return empty string
        return '';
      }
      if (!cacheExists) {
        this._memo.set(
          memoKey,
          this._cosTranslocoService.translate(withPrefix, params)
        );
      }

      return this._memo.get(memoKey);
    };
  }
}
