/* eslint-disable @angular-eslint/no-input-rename */
import {
  Directive,
  inject,
  Input,
  TemplateRef,
  type OnChanges,
  type OnDestroy,
  type OnInit,
  type SimpleChanges,
} from '@angular/core';
import {
  TemplateHandler,
  // eslint-disable-next-line no-restricted-syntax
  TranslocoDirective,
  type HashMap,
} from '@ngneat/transloco';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  scan,
  switchMap,
} from 'rxjs';

import type { LanguageScope } from '../consts';
import { CosmosTranslocoService } from '../services';

@Directive({
  selector: '[cosTranslate]',
})
export class CosTranslateDirective
  extends TranslocoDirective
  implements OnInit, OnChanges, OnDestroy
{
  @Input('cosTranslate') override key: string | undefined;
  @Input('cosTranslateParams') override params: HashMap = {};
  @Input('cosTranslateScope') override inlineScope: string | undefined;
  @Input({ alias: 'cosTranslateRead', required: true })
  override inlineRead!: string;
  @Input('cosTranslateLang') override inlineLang: string | undefined;
  @Input('cosTranslateLoadingTpl') override inlineTpl:
    | TemplateRef<unknown>
    | undefined;

  private readonly _cosTranslocoService = inject(CosmosTranslocoService);

  private readonly _keySubject$ = new BehaviorSubject<string | undefined>(
    undefined
  );
  private readonly _inlineScopeChanges$: Observable<LanguageScope[]> =
    this._keySubject$.pipe(
      filter((v): v is string => !!v),
      distinctUntilChanged(),
      map((key) => this._cosTranslocoService._deduceScopeFromKey(key)),
      scan((acc, c) => (c ? acc.concat(c) : acc), <LanguageScope[]>[])
    );

  override ngOnInit() {
    // Logic below has been almost completely copied from transloco's directive since it's not really possible to extend it.
    // Casting "this" as "any" in order to access private properties of the TranslocoDirective.
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const untypedThis: any = this;
    this.subscription = combineLatest([
      this._cosTranslocoService.langChanges$,
      this._inlineScopeChanges$,
    ])
      .pipe(
        switchMap(([activeLang, scopes]) => {
          const lang = untypedThis.langResolver.resolve({
            inline: this.inlineLang,
            provider: untypedThis.providerLang,
            active: activeLang,
          });
          const allScopes = scopes
            .concat(untypedThis.providerScope)
            .flat(Infinity);

          return forkJoin(
            allScopes.map((providerScope) =>
              untypedThis.resolveScope(lang, providerScope)
            )
          );
        })
      )
      .subscribe(() => {
        untypedThis.currentLang =
          untypedThis.langResolver.resolveLangBasedOnScope(untypedThis.path!);
        untypedThis.strategy === 'attribute'
          ? untypedThis.attributeStrategy()
          : untypedThis.structuralStrategy(
              untypedThis.currentLang,
              untypedThis.inlineRead
            );
        untypedThis.cdr.markForCheck();
        untypedThis.initialized = true;
      });
    const loadingTpl = untypedThis.getLoadingTpl();
    if (!untypedThis.initialized && loadingTpl) {
      untypedThis.loaderTplHandler = new TemplateHandler(
        loadingTpl,
        untypedThis.vcr
      );
      untypedThis.loaderTplHandler.attachView();
    }
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes['inlineRead']) {
      this._keySubject$.next(this.inlineRead);
    }
  }
}
