import { Pipe, type PipeTransform } from '@angular/core';

import type { Languages } from '../consts';
import { CosTranslatePipe } from '../overrides';

// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: 'languageLabel', standalone: true, pure: false })
export class LanguageLabelPipe
  extends CosTranslatePipe
  implements PipeTransform
{
  override transform(value: Languages): string {
    const langKey = `espCommon.localization.${value}`;
    return super.transform(langKey);
  }
}
