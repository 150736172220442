/// <reference types="zone.js" />
import { InjectionToken } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { unpatchedSetTimeout } from '@cosmos/zone-less';

// This resolves to `Observable<true>` in order to allow simpler
// usage with `@defer (when ...)`.
export const IDLE$ = new InjectionToken<Observable<true>>(
  ngDevMode ? 'requestIdleCallback provider' : '',
  {
    providedIn: 'root',
    factory: () => {
      if (global_isServer) {
        const promise = Promise.resolve(<const>true);
        // We should be scheduling through a promise on the Node.js side since there's no
        // similar API available.
        return defer(() => promise);
      } else {
        // Angular is using the same approach.
        const _requestIdleCallback =
          typeof requestIdleCallback !== 'undefined'
            ? requestIdleCallback
            : unpatchedSetTimeout;

        const _cancelIdleCallback =
          typeof requestIdleCallback !== 'undefined'
            ? cancelIdleCallback
            : clearTimeout;

        return new Observable<true>((subscriber) => {
          const handle = _requestIdleCallback(() => {
            subscriber.next(true);
            subscriber.complete();
          });

          return () => _cancelIdleCallback(handle);
        });
      }
    },
  }
);
