import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

import { BaseStorageService } from './abstract-storage.service';
import { MemoryStorage } from './memory-storage';

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends BaseStorageService {
  constructor() {
    try {
      super(
        global_isServer ? new MemoryStorage() : inject(WINDOW).localStorage
      );
    } catch (error) {
      ngDevMode &&
        console.warn(
          'Failed to retrieve the instance of "localStorage", falling back to "MemoryStorage"',
          error
        );
      super(new MemoryStorage());
    }
  }
}
