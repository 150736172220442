import { Injectable } from '@angular/core';
import {
  DefaultTranspiler,
  getValue,
  isObject,
  setValue,
  type HashMap,
  type Translation,
} from '@ngneat/transloco';
import { IntlMessageFormat } from 'intl-messageformat';

import { escapeHtmlTags } from '../utils';

@Injectable()
export class IntlMessageformatTranspiler extends DefaultTranspiler {
  private readonly _cache = new Map<string, IntlMessageFormat>();

  override transpile(
    value: any,
    params: HashMap = {},
    translation: Translation,
    key: string
  ): any {
    if (!value) {
      return value;
    }
    if (isObject(value) && params) {
      Object.keys(params).forEach((p) => {
        const v = getValue(value, p);
        const getParams = getValue(params, p);
        const transpiled = super.transpile(v, getParams, translation, key);
        const message = this._getFormatInstance(transpiled).format<string>(
          params[p]
        );
        value = setValue(value, p, message);
      });
    } else if (!Array.isArray(value)) {
      const transpiled = super.transpile(value, params, translation, key);

      const message =
        this._getFormatInstance(transpiled).format<string>(params);

      return message;
    }

    return value;
  }

  private _getFormatInstance(transpiled: any): IntlMessageFormat {
    if (!this._cache.has(transpiled)) {
      try {
        this._cache.set(
          transpiled,
          new IntlMessageFormat(escapeHtmlTags(transpiled))
        );
      } catch (error) {
        throw new Error(
          `IntlMessageFormat failed to parse the following expression: "${transpiled}". Raw error: ${error?.toString?.()}`
        );
      }
    }

    return this._cache.get(transpiled)!;
  }
}
