import { inject } from '@angular/core';
import { TRANSLOCO_SCOPE, type TranslocoScope } from '@ngneat/transloco';
import { kebabCase } from 'lodash-es';

import type { Languages, LanguageScope } from '../consts';

/** Returns an array of language scopes in the current injection context */
export function getLanguageScopes(): LanguageScope[] | null {
  const scopes = inject(TRANSLOCO_SCOPE, { optional: true }) as unknown as
    | TranslocoScope[]
    | null;

  return scopes && translocoToLanguageScopes(scopes);
}

export function translocoToLanguageScopes(
  scopes: (LanguageScope | TranslocoScope)[]
): LanguageScope[] {
  return scopes
    .map((s) => (typeof s === 'string' ? s : s?.scope))
    .filter((s): s is LanguageScope => !!s);
}

export function languageScopeToPath(scope: Languages): string {
  // For scoped languages values here look like "espCommon/en-us".
  // Since we're using kebab case for folder names, converting "lang" value into "esp-common/en-us".
  return scope
    .split('/')
    .map((s) => kebabCase(s))
    .join('/');
}
