import { share, tap } from 'rxjs/operators';
function Share(opts = {}) {
  return (target, propertyKey, descriptor) => {
    if (!descriptor.value) {
      return descriptor;
    }
    const originalMethod = descriptor.value;
    const cachePropName = Symbol('cacheProp');
    descriptor.value = function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      if (!context[cachePropName]) {
        context[cachePropName] = new Map();
      }
      const cache = context[cachePropName];
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      const key = `${String(propertyKey)}-${JSON.stringify([...args])}`;
      const updatedCall = originalMethod.apply(context, args).pipe(share(), tap(() => cache.delete(key)));
      if (!opts.when || opts.when.apply(context, args)) {
        const loadingInProcess = cache.get(key);
        if (loadingInProcess) {
          return loadingInProcess;
        }
        cache.set(key, updatedCall);
      }
      return updatedCall;
    };
    return descriptor;
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { Share };
