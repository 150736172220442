/// <reference types="zone.js" />
/// <reference types="zone.js" />
import type { NgZone } from '@angular/core';
import { Observable, type MonoTypeOperatorFunction } from 'rxjs';

export function enterNgZone<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
  return function enterNgZoneOperator(source: Observable<T>) {
    return new Observable<T>((subscriber) =>
      source.subscribe({
        next: (value) => ngZone.run(() => subscriber.next(value)),
        error: (error) => ngZone.run(() => subscriber.error(error)),
        complete: () => ngZone.run(() => subscriber.complete()),
      })
    );
  };
}

export function leaveNgZone<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
  return function leaveNgZoneOperator(source: Observable<T>) {
    // Do not leave the zone on the Node.js side.
    if (global_isServer) {
      return source;
    }

    return new Observable<T>((subscriber) =>
      source.subscribe({
        next: (value) => ngZone.runOutsideAngular(() => subscriber.next(value)),
        error: (error) =>
          ngZone.runOutsideAngular(() => subscriber.error(error)),
        complete: () => ngZone.runOutsideAngular(() => subscriber.complete()),
      })
    );
  };
}
