import { memoize } from 'lodash-es';

function _getEnumEntries<T extends object>(
  enumType: T
): { key: keyof T; value: T[keyof T] }[] {
  let entries = Object.entries(enumType) as [keyof T, T[keyof T]][];
  const plainStringEnum = entries.every(
    ([, value]) => typeof value === 'string'
  );
  if (!plainStringEnum) {
    const filtered = entries.filter(([, value]) =>
      /^\d+$/.test(value as string)
    );
    if (filtered.length !== entries.length / 2) {
      // can work only with enum, that has all values as string or numbers
      // if the enum has all values as numbers, all of the values should be unique
      throw new Error('Cannot process enum with mixed or equal values');
    }
    entries = filtered;
  }
  return entries.map(([key, value]) => ({ key, value }));
}

/**
 * Returns an array of all key/value pairs from an enum.
 * Will process the enum if it has all values as strings or numbers. Otherwise will throw an error
 *
 * @param enumType an enum you want to extract the key/value pairs from
 */
export const getEnumEntries: typeof _getEnumEntries = memoize(
  _getEnumEntries,
  (enumObj) => JSON.stringify(enumObj)
);
