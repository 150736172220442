import 'dayjs/esm/locale/en-ca';
import 'dayjs/esm/locale/fr-ca';
import 'dayjs/esm/locale/es';

import { registerLocaleData } from '@angular/common';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEs from '@angular/common/locales/es';
import localeFrCa from '@angular/common/locales/fr-CA';

import { dayjs } from '@cosmos/util-dayjs';

// set the default locale
dayjs.locale('en-US');

registerLocaleData(localeEnCa);
registerLocaleData(localeFrCa);
registerLocaleData(localeEs);
