declare global {
  interface NetworkInformation {
    // Chrome 65+ | Edge 79+ | Opera
    saveData?: boolean;
    // Chrome 61+ | Edge 79+ | Opera
    effectiveType?: EffectiveType;
  }
}

type EffectiveType = 'slow-2g' | '2g' | '3g' | '4g';

const avoidTheseConnections: EffectiveType[] = [
  'slow-2g',
  '2g',
  '3g' /* ,  '4g' */,
];

export function hasGoodConnection(navigator: Navigator): boolean {
  // This is a known TS types definition issue.
  const { connection } = navigator as unknown as {
    connection: NetworkInformation;
  };

  if (connection) {
    if (
      connection.saveData ||
      (connection.effectiveType &&
        avoidTheseConnections.includes(connection.effectiveType))
    ) {
      return false;
    }
  }

  return true;
}
