// eslint-disable-next-line no-restricted-syntax
import dayjs, { type ConfigType, type Dayjs } from 'dayjs/esm';
import advancedFormat from 'dayjs/esm/plugin/advancedFormat';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';
import duration, { type Duration } from 'dayjs/esm/plugin/duration';
import localeData from 'dayjs/esm/plugin/localeData';
import localizedFormat from 'dayjs/esm/plugin/localizedFormat';
import objectSupport from 'dayjs/esm/plugin/objectSupport';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import timezone from 'dayjs/esm/plugin/timezone';
import utc from 'dayjs/esm/plugin/utc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exported: typeof dayjs = (dayjs as any)
  .extend(relativeTime)
  .extend(localeData)
  .extend(localizedFormat)
  .extend(utc)
  .extend(timezone)
  .extend(advancedFormat)
  .extend(duration)
  .extend(customParseFormat)
  .extend(objectSupport);

export { exported as dayjs, type Dayjs, type ConfigType, type Duration };

export function getTimezoneAbbreviation(date: Dayjs) {
  // Returns `EST|EEST|EDT`, etc.
  try {
    // I found on Stack Overflow that `dayjs` doesn't have a similar
    // API for displaying the time zone abbreviation correctly. For example,
    // it doesn't show EEST, only EST.
    const abbreviation = date
      .toDate()
      .toString()
      // eslint-disable-next-line no-useless-escape
      .match(/\(([^\)]+)\)$/)!;

    return abbreviation[1].match(/[A-Z]/g)!.join('');
  } catch {
    return '';
  }
}

export function parseAndSetTime(date: Dayjs | null, time: string | null) {
  if (!time) return date;

  const parts = time.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);

  return (
    dayjs(date)
      .local()
      .set({ second: 0, millisecond: 0 })
      // we keep selected "date" in user local timezone
      // in case if user timezone is UTC+02:00 and user selects date as "2024-10-04"
      // the "date" model is gong to be as "2024-10-03T22:00:00Z"
      // because of this we have to add selected hours and minutes to the date
      // instead of just setting these values
      .add({ hour: hours, minute: minutes })
  );
}
